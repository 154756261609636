<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
  <div class="container container-fluid mt-4" v-if="isIntentoAdmin">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ name: 'intentoDashboard' }">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
        Dashboard
      </b-breadcrumb-item>
      <b-breadcrumb-item :to="{ name: 'userDashboard' }">
        User Management
      </b-breadcrumb-item>
    </b-breadcrumb>

    <b-alert :show="loading" variant="info">Loading...</b-alert>

    <b-row>
      <b-col>
        <table class="table table-striped user-table">
          <thead>
            <tr>
              <th>Username/Email</th>
              <th>Name</th>
              <th>Role</th>
              <th>Last Modification</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.userName }}</td>
              <td>{{ user.name }}</td>
              <td>
                <b-list-group>
                  <b-list-group-item
                    v-for="(role, key) in user.roles"
                    class="user-role"
                    :key="key"
                    >{{ role }}</b-list-group-item
                  >
                </b-list-group>
              </td>
              <td>
                {{ toLocaleDate(user.modificationDatetime) }} (user ID
                {{ user.modifiedByUser }})
              </td>
              <td class="text-right">
                <b-row>
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    class="m-1"
                    @click.prevent="populateIntentoUserToEdit(user)"
                  >
                    <b-icon-pencil />
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    class="my-1"
                    @click.prevent="deleteIntentoUser(user.id)"
                  >
                    <b-icon-trash />
                  </b-button>
                </b-row>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>

      <b-modal
        id="modal-add-intento-user"
        @ok="saveIntentoUser"
        ok-title="Save User"
        :title="model.id ? 'Edit User' : 'Create a new user'"
      >
        <form @submit.prevent="saveIntentoUser">
          <b-form-group label="Name">
            <b-form-input type="text" v-model="model.name"></b-form-input>
          </b-form-group>
          <b-form-group label="Email">
            <b-form-input type="email" v-model="model.email"></b-form-input>
          </b-form-group>
          <b-form-group label="Roles">
            <b-form-checkbox-group
              v-model="model.roles"
              :options="roleTypes"
              class="checkbox-group"
            />
          </b-form-group>
          <b-form-group label="Password">
            <b-row>
              <b-col>
                <b-form-input
                  :type="passwordFieldType"
                  v-on:click="showPasswordInstructions = true"
                  v-model="model.password"
                ></b-form-input>
              </b-col>
              <b-col md="auto">
                <b-icon-eye
                  class="center"
                  @mouseover="passwordFieldType = 'text'"
                  @mouseleave="passwordFieldType = 'password'"
                />
              </b-col>
            </b-row>
            <div v-show="showPasswordInstructions" class="form-group">
              The new password will need:
              <ul>
                <li>At least eight characters</li>
                <li>At least one upper case English letter</li>
                <li>At least one lower case English letter</li>
                <li>At least one digit</li>
                <li>
                  At least one special character from this list: #?!@$%^&*-{}~
                </li>
              </ul>
            </div>
          </b-form-group>
        </form>
        <div class="form-group">
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>
        </div>
      </b-modal>
    </b-row>

    <b-row>
      <b-col md="1">
        <b-button v-b-modal.modal-add-intento-user size="sm" variant="primary">
          <b-icon-plus font-scale="1.3"></b-icon-plus>Add User
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDateToLocaleString } from "@/components/utils.js";
import ApiService from "@/services/api.service";

export default {
  data() {
    return {
      loading: false,
      users: [],
      model: {},
      roleTypes: [
        { value: "IntentoAdmin", text: "Admin" },
        { value: "IntentoSales", text: "Sales" },
        { value: "IntentoSoftware", text: "Software Maintenance" },
        { value: "DPO", text: "DPO" },
      ],
      showPasswordInstructions: false,
      errorMessage: "",
      passwordFieldType: "password",
    };
  },
  async created() {
    this.refreshIntentoUsers();
  },
  computed: {
    ...mapGetters("auth", ["isIntentoAdmin"]),
  },
  methods: {
    async refreshIntentoUsers() {
      this.loading = true;
      this.users = await ApiService.getIntentoUsers();
      this.loading = false;
    },
    async populateIntentoUserToEdit(user) {
      this.errorMessage = "";
      this.model = Object.assign({}, user);
      this.$bvModal.show("modal-add-intento-user");
    },
    async saveIntentoUser(event) {
      event.preventDefault();
      this.loading = true;
      this.model.userName = this.model.email;
      if (this.model.id) {
        await ApiService.updateUser(this.model.id, this.model).then(
          () => {
            this.model = {}; // reset form
            this.loading = false;
            this.errorMessage = "";
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add-intento-user");
            });
          },
          (error) => {
            this.loading = false;
            this.errorMessage =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        this.model.type = "IntentoUser";
        await ApiService.addUser(this.model).then(
          () => {
            this.loading = false;
            this.model = {}; // reset form
            this.errorMessage = "";
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add-intento-user");
            });
          },
          (error) => {
            this.loading = false;
            this.errorMessage =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
      await this.refreshIntentoUsers();
    },
    async deleteIntentoUser(id) {
      if (confirm(`Are you sure you want to delete user#${id} ?`)) {
        // if we are editing a user we deleted, remove it from the form
        if (this.model.Id === id) {
          this.model = {};
        }
        await ApiService.deleteUser(id);
        await this.refreshIntentoUsers();
      }
    },
    toLocaleDate(d) {
      return formatDateToLocaleString(d);
    },
  },
};
</script>

<style scoped>
.user-role {
  padding: 5px;
  padding-left: 15px;
}
</style>